<template>
  <div class="main-container">
    <router-view />
  </div>
</template>
<script setup>
import { getOssConfig } from "@/js/api";
import { reactive, ref, provide } from "vue";
import store from "@/js/store";
import Bus from "@/js/bus";
import { useRouter } from "vue-router";
const ossConfig = ref();
const router = useRouter();
getOssConfig().then((res) => {
  console.log(res);
  ossConfig.value = res.data;
  store.action.setOssConfig(res.data);
});
const toScreen = () => {
  Bus.$emit("screen");
};
provide("ossConfig", ossConfig);
</script>
<style lang="less" scoped>
.main-container {
  margin: auto;
  min-height: 100vh;
  // padding-bottom: 200px;
  background: #dbdbdb;
}
</style>
