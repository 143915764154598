class Bus {
  constructor() {
    this.list = {};
  }

  $on(name, fn) {
    this.list[name] = fn;
  }
  $emit(name, data) {
    let res;
    if (this.list[name]) {
      res = this.list[name](data);
    }
    return res;
  }
}

export default new Bus();
