import { reactive } from "vue";
const store = reactive({
  state: {
    ossConfig: {},
    userInfo: {},
    isLogin: false,
  },
  action: {
    setOssConfig(data) {
      store.state.ossConfig = data;
    },
    setUserInfo(data) {
      store.state.isLogin = true;
      store.state.userInfo = data;
    },
  },
});
export default store;
