import Container from "@/components/Container";
import store from "@/js/store";
import { showToast } from "vant";
import { computed } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/Login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/Register",
    name: "Register",
    component: () => import("@/views/Register.vue"),
  },
  {
    path: "/Main",
    name: "Main",
    component: () => import("@/views/Main"),
    children: [
      {
        path: "/WeChat",
        name: "WeChat",
        component: () => import("@/views/WeChat/Index"),
        children: [
          {
            path: "/WeChat/ChatList",
            name: "WeChatChatList",
            component: () => import("@/views/WeChat/ChatList"),
          },
          {
            path: "/WeChat/ChatWindow/:id?",
            name: "WeChatChatWindow",
            component: () => import("@/views/WeChat/ChatWindow.vue"),
          },
          {
            path: "/WeChat/ChatSeting/:id?",
            name: "WeChatChatSeting",
            component: () => import("@/views/WeChat/ChatSeting.vue"),
          },
          {
            path: "/WeChat/My",
            name: "WeChatMy",
            component: () => import("@/views/WeChat/My/Index.vue"),
          },
          {
            path: "/WeChat/MyInfoSet",
            name: "WeChatMyInfoSet",
            component: () => import("@/views/WeChat/My/MyInfoSet"),
          },
          {
            path: "/WeChat/MyServer",
            name: "WeChatMyServer",
            component: () => import("@/views/WeChat/My/MyServer"),
          },
          {
            path: "/WeChat/Money",
            name: "WeChatMoney",
            component: () => import("@/views/WeChat/My/Money"),
          },
          {
            path: "/WeChat/MyInfoSet",
            name: "WeChatMyInfoSet",
            component: () => import("@/views/WeChat/My/MyInfoSet"),
          },
          {
            path: "/WeChat/FriendList",
            name: "WeChatFriendList",
            component: () => import("@/views/WeChat/FriendList"),
          },
        ],
      },
      {
        path: "/UserCenter",
        name: "UserCenter",
        component: () => import("@/views/UserCenter.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
const whiteList = ["Login", "Register", "ChatList"];
const vipList = ["ChatWindow", "Money", "MyServer"];
const isLogin = computed(() => store.state.isLogin);
const isExpire = computed(() => {
  const currentTime = new Date().getTime();
  const expireTime = new Date(store.state.userInfo?.endTime).getTime();
  if (expireTime > currentTime) {
    return false;
  } else {
    return true;
  }
});
router.beforeEach((to, from, next) => {
  console.log(isExpire.value);
  // 跳过白名单
  if (whiteList.includes(to.name)) {
    next();
  } else if (isLogin.value) {
    // 判断会员
    if (vipList.includes(to.name) && isExpire.value) {
      showToast("用户到期");
    } else {
      next(); // 继续导航到目标路由
    }
  } else {
    // 如果用户未登录且正在前往非登录页面的其他路由，则重定向到登录页面
    next("/login");
    showToast("请先登录");
  }
});

export default router;
