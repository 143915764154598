import store from "@/js/store";
import axios from "axios";
import dayjs from "dayjs";
const OSS = require("ali-oss");
// 创建一个 Axios 实例
const request = axios.create({
  baseURL: "",
  timeout: 10000, // 请求超时时间
});
// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 在这里对响应数据做处理
    // 可以根据需要进行数据转换、添加额外处理逻辑等
    return response.data; // 返回处理后的数据
  },
  function (error) {
    // 对响应错误做处理
    // 可以根据错误状态码进行特定处理
    return Promise.reject(error);
  }
);
export function getOssConfig() {
  return request({
    url: "/api/oss/getOssSgin",
    method: "get",
  });
}
export function login(data) {
  return request({
    url: "/api/user/login",
    method: "post",
    data,
  });
}
export function register(data) {
  return request({
    url: "/api/user/register",
    method: "post",
    data,
  });
}
export function recharge(data) {
  return request({
    url: "/api/user/recharge",
    method: "post",
    data,
  });
}
export function backupsData(data) {
  return request({
    url: "/api/user/backupsData",
    method: "post",
    data,
  });
}
export function restoreData(data) {
  return request({
    url: "/api/user/restoreData",
    method: "post",
    data,
  });
}
export function upload(file) {
  // const ossSginInfo = inject("ossConfig");
  return new Promise((resolve) => {
    const ossSginInfo = store.state.ossConfig;
    const uploadUrl = "https://mockchat.oss-cn-beijing.aliyuncs.com/";
    const { OSSAccessKeyId, policy, signature } = ossSginInfo;
    const fileName = new Date().getTime() + ".jpg";
    const today = dayjs().format("YYYY-MM-DD");
    const key = `images/${today}/${fileName}`;
    const fullPath = uploadUrl + key;
    const formData = new FormData();
    formData.append("name", fileName);
    formData.append("policy", policy);
    formData.append("OSSAccessKeyId", OSSAccessKeyId);
    formData.append("success_action_status", "200");
    formData.append("signature", signature);
    formData.append("key", key);
    // file必须为最后一个表单域，除file以外的其他表单域无顺序要求。
    formData.append("file", file);
    axios({
      method: "post",
      url: uploadUrl,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      if (res.status === 200) {
        resolve("/" + key);
      }
    });
  });
}
export function upload2(file) {
  // const ossSginInfo = inject("ossConfig");
  const ossSginInfo = store.state.ossConfig;
  const { OSSAccessKeyId, policy, signature } = ossSginInfo;

  console.log(ossSginInfo);
  const client = new OSS({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
    region: "oss-cn-beijing",
    // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
    accessKeyId: "LTAI5tCty18Hnj82eD8huBhQ",
    accessKeySecret: "j9XNYdhv3HkW8vLF8DBp6xiDmbuT6u",
    // 从STS服务获取的安全令牌（SecurityToken）。
    // 填写Bucket名称。
    bucket: "mockchat",
  });
  const fileName = new Date().getTime() + ".jpg";
  const today = dayjs().format("YYYY-MM-DD");
  const key = `goodsPictures/${today}/${fileName}`;
  // const fullPath = uploadUrl + key;

  client.put(key, file).then((res) => {
    console.log(res);
  });
}
