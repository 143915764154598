import "@/assets/css/global.less";
import Vant, { Icon } from "vant";
import "vant/lib/index.css";
import VConsole from "vconsole";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// 可以根据需要进行环境判断，例如在开发环境下才引入 VConsole
if (process.env.NODE_ENV === "development") {
  // const vConsole = new VConsole();
}
const app = createApp(App);
app.use(Icon);
app.use(Vant);
// app.use(Vant.Lazyload);
app.use(router);
app.mount("#app");
